import { useContext, useEffect, useState } from "react";
import {
  Button, Container, Grid, Typography, useTheme, Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Select,
  TextField,
  InputLabel,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { Edit, Https, Notifications, NotificationsOff } from "@mui/icons-material";
// components
import Label from "../../components/label";
import Scrollbar from "../../components/scrollbar";
import Iconify from "../../components/iconify";
import { AppWidgetSummary } from "../../sections/@dashboard/app";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
import AddShopOwner from "../../dialogBoxs/AddShopOwner";
import EditShopOwner from "../../dialogBoxs/EditShopOwner";
import ChangeOwnerPassword from "../../dialogBoxs/ChangeOwnerPassword";
// mock
import USERLIST from "../../_mock/user";
import OWNERLIST from '../../_mock/shopOwner.json';
import { getCashier, getShop, getShopOwners, searchSlip } from "../../data/fetchShopOwner";
import { CampaignContext } from "../../layouts/dashboard/DashboardLayout";
import AddShop from "../../dialogBoxs/shop/AddShop";
import EditShop from "../../dialogBoxs/shop/EditShop";

import '../../styles/filter.scss'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
  // { id: "id", label: "Id", alignRight: false },
  { id: "id", label: "Game", alignRight: false },
  { id: "gameNumber", label: "Game number", alignRight: false },
  { id: "stake", label: "Stake", alignRight: false },
  { id: "num", label: "Picked numbers", alignRight: false },
  { id: "win", label: "Net win", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

export default function SlipDetail(params) {
  const theme = useTheme();
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditData, setOpenEditData] = useState({});

  const { data, dispatch } = useContext(CampaignContext);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [owner, setOwner] = useState(null);
  const [ticketNumber, setTicketNumber] = useState("");


  const handleFetchData = () => {
    searchSlip(owner, setOwner, dispatch, setLoader, ticketNumber);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = owner.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleEdit = (row) => {
    console.log('row', row);
    setOpenEditData({
      id: row.id,
      name: row.name,
      username: row.username,
      cashierLimit: row.cashierLimit,
      location: row.location,
      maxStake: row.maxStake,
      minStake: row.minStake,
      owner: row.owner,
      shopOwnerId: row.shopOwnerId,
      status: row.status
    })
    setOpenEdit(true);
  }

  const emptyRows =
    (page > 0 && owner) ? Math.max(0, (1 + page) * rowsPerPage - owner?.length) : 0;

  // const isNotFound = !owner?.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>
      <Container maxWidth="xl">
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Slip Detail
          </Typography>
        </Stack> */}

        <Card sx={{ margin: '1rem 0' }}>
          <Grid container spacing={3} className="fillterGridLeft">
            <Grid item xs={9} sm={9} xl={6}>
              <TextField type="number" placeholder="Slip number" value={ticketNumber} onChange={(e) => setTicketNumber(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={3} sm={3} xl={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              {
                loader ? (
                  <Button variant="contained" fullWidth style={{ height: '48px', padding: '10px', background: '#7FD858' }}><CircularProgress sx={{ color: 'white' }} /></Button>
                ) : (
                  <Button variant="contained" fullWidth onClick={handleFetchData} style={{ height: '48px', background: '#7FD858' }}>Search Slip</Button>
                )
              }
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ margin: '1rem 0' }}>
          <Scrollbar >
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  // rowCount={1}
                  // numSelected={1}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {/* loader ? (<div style={{ height: '50px' }}><CircularProgress /></div>) : */}
                  {
                    owner && (
                      <TableRow
                        hover
                      // key={owner.id}
                      // tabIndex={-1}
                      // role="checkbox"
                      // selected={selectedUser}
                      >
                        <TableCell>
                          {owner.id}
                        </TableCell>

                        <TableCell align="left">{owner?.gameType}</TableCell>
                        <TableCell align="left">{owner.game?.gameNumber}</TableCell>
                        <TableCell align="left">{owner.totalStake}</TableCell>
                        <TableCell align="left">
                          {(owner?.numberPick) && JSON.parse(owner?.numberPick).map((pick, index) => (
                            <span key={index}>
                              {pick.selection && Array.isArray(pick.selection)
                                ? pick.selection.join(', ')
                                : pick.selection}
                              {pick.val && Array.isArray(pick.val)
                                ? pick.val.join(', ')
                                : pick.val}
                              {index < JSON.parse(owner?.numberPick).length - 1 && ' : '}
                            </span>
                          ))}</TableCell>
                        <TableCell align="left">{owner.netWinning}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {owner.status}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )

                  }
                  {(false && !loader) && (
                    <TableRow
                      style={{
                        height: '40px',
                      }}
                    >
                      <TableCell
                        colSpan={8}
                        align="center"
                        style={{
                          height: "paddingHeight",
                          padding: "40px",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        No Shops Found
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {/* {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

        </Card>
      </Container>
      {/* <AddShop open={open} setOpen={setOpen} handleFetchData={handleFetchData} /> */}
    </>
  );
};


// // Example players with various selections and stakes
// const players = [
//   { selectedNumbers: [4, 5], coinsPlaced: 10 },
//   { selectedNumbers: [3], coinsPlaced: 20 },
//   { selectedNumbers: [1, 3], coinsPlaced: 10 },
//   { selectedNumbers: [3], coinsPlaced: 20 },
//   { selectedNumbers: [4, 2], coinsPlaced: 10 },
//   { selectedNumbers: [3], coinsPlaced: 20 },
//   { selectedNumbers: [6, 5], coinsPlaced: 10 },
//   { selectedNumbers: [1], coinsPlaced: 20 },
//   // ... other players
// ];

// function drawTwoUniqueNumbers(weights) {
//   const drawnNumbers = new Set();
//   console.log('weight', weights)
//   while (drawnNumbers.size < 2) {
//     const candidateNumber = weightedRandom(weights);
//     if (!drawnNumbers.has(candidateNumber)) {
//       drawnNumbers.add(candidateNumber);
//     }
//   }
//   return Array.from(drawnNumbers).sort(); // Ensure sorted order
// }

// function weightedRandom(weights) {
//   const totalWeight = weights.reduce((sum, weight) => sum + weight.weight, 0);
//   const randomValue = Math.random() * totalWeight;

//   let cumulativeWeight = 0;
//   for (let i = 0; i < weights.length; i++) {
//     cumulativeWeight += weights[i].weight;
//     if (randomValue <= cumulativeWeight) {
//       return weights[i].value; // Return the selected number
//     }
//   }
// }

// function calculateWeights(players) {
//   // Create an array to store all possible numbers
//   const allNumbers = Array.from({ length: 6 }, (_, i) => i + 1); // [1, 2, 3, 4, 5, 6]

//   // Initialize empty object to store total coins placed
//   const coinsSum = {};

//   // Iterate through players and count their bets
//   players.forEach(player => {
//     player.selectedNumbers.forEach(number => {
//       coinsSum[number] = (coinsSum[number] || 0) + player.coinsPlaced / player.selectedNumbers.length;
//     });
//   });

//   // Calculate total coins placed
//   const totalCoinsPlaced = Object.values(coinsSum).reduce((sum, value) => sum + value, 0);

//   // Calculate base weight (average coins placed per number)
//   const baseWeight = totalCoinsPlaced / allNumbers.length;

//   // Return weights for all numbers
//   return allNumbers.map(number => ({
//     value: number,
//     weight: coinsSum[number] ? baseWeight / coinsSum[number] : baseWeight, // Lower weight for selected numbers
//   }));
// }

// function simulateGame(players, numDrawnNumbers = 2) {
//   const weights = calculateWeights(players);

//   // Draw the specified number of unique numbers
//   const drawnNumbers = drawTwoUniqueNumbers(weights, numDrawnNumbers);

//   let totalCoinsPlaced = 0;
//   let totalWinnings = 0;

//   // Calculate winnings for each player
//   players.forEach(player => {
//     // Check for matching numbers using a counter
//     let matchingNumbers = 0;
//     for (const drawnNumber of drawnNumbers) {
//       if (player.selectedNumbers.includes(drawnNumber)) {
//         matchingNumbers++;
//       }
//     }

//     // Calculate winning odd based on number of matches
//     const odd = {
//         1: [{ 0: 0 }, { 1: 2 }],
//         2: [{ 0: 0 }, { 1: 1 }, { 2: 3 }],
//     };

//     const winningOdd = odd[numDrawnNumbers][matchingNumbers - 1] && Object?.values(odd[numDrawnNumbers][matchingNumbers - 1]) || 0; // Use default of 0 for missing entries

//     // Assign winnings based on winning odd
//     player.winnings = player.coinsPlaced * winningOdd;

//     totalWinnings += player.winnings;
//     totalCoinsPlaced += player.coinsPlaced;
//   });

//   return { drawnNumbers, totalCoinsPlaced, totalWinnings };
// }

// // Simulate the game and get the result
// const gameResult = simulateGame(players);

// // Display the result and player details
// console.log(`Drawn Numbers: ${gameResult.drawnNumbers.join(', ')}`);
// console.log(`Total Coins Placed: ${gameResult.totalCoinsPlaced}`);
// console.log(`Total Winnings: ${gameResult.totalWinnings}`);
// console.log(`Total Net: ${gameResult.totalCoinsPlaced - gameResult.totalWinnings}`);
// players.forEach((player, index) => {
//   console.log(`Player ${index + 1}: Selected ${player.selectedNumbers}, Placed Bet ${player.coinsPlaced}, Winnings ${player.winnings}, Net ${player.winnings - player.coinsPlaced}`);
// });
