import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Download } from '@mui/icons-material';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Button } from '@mui/material';
// component
import Iconify from '../../components/iconify';
import DownloadExcel from '../../hooks/downloadExcel';
import exportDataToExcel from '../../hooks/exportDataToExcel';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'right',
  // justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

RetailListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  filterDate: PropTypes.array,
  onFilterName: PropTypes.func,
  date: PropTypes.array,
  cash: PropTypes.bool
};
export default function RetailListToolbar({ numSelected, filterName, filterDate, onFilterName, date, cash }) {
  const data = filterDate.map(row => (parseInt(row.totalTickets, 10) && {
    ShopOwner: row.shopOwner ? row.shopOwner.name : '',
    Shop: row.shop ? row.shop.name : '',
    ...(cash && { Cashier: row.cashier.username }),
    Tickets: row.totalTickets,
    TotalBet: row.totalStake,
    Payout: row.totalPayout,
    // totalPayoutCount: row.totalPayoutCount,
    // totalUnclaimed: row.totalUnclaimed,
    // totalUnclaimedCount: row.totalUnclaimedCount,
    // totalRevoked: row.totalRevoked,
    // totalRevokedCount: row.totalRevokedCount,
    // totalGGR: row.totalGGR,
    NetBalance: row.totalNetBalance,
  }))
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>

        </>
        // <StyledSearch
        //   value={filterName}
        //   onChange={onFilterName}
        //   placeholder="Search shop and shop owner..."
        //   startAdornment={
        //     <InputAdornment position="start">
        //       <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
        //     </InputAdornment>
        //   }
        // />
      )}

      <div>

        <Button variant='outlined' onClick={() => exportDataToExcel(`Rock game report from ${new Date(date.from).toISOString().substring(0, 10)} to ${new Date(date.to).toISOString().substring(0, 10)}`, data, cash)} > <Download /> Export</Button>
      </div>
      {/* <DownloadExcel
        data={filterDate.map(row => ({
          ShopOwner: row.shopOwner ? row.shopOwner.name : '',
          Shop: row.shop ? row.shop.name : '',
          Tickets: row.totalTickets,
          TotalBet: row.totalStake,
          Payout: row.totalPayout,
          // totalPayoutCount: row.totalPayoutCount,
          // totalUnclaimed: row.totalUnclaimed,
          // totalUnclaimedCount: row.totalUnclaimedCount,
          // totalRevoked: row.totalRevoked,
          // totalRevokedCount: row.totalRevokedCount,
          // totalGGR: row.totalGGR,
          NetBalance: row.totalNetBalance,
        }))}
        filename={`Rock game report from ${new Date(date.from).toISOString().substring(0, 10)} to ${new Date(date.to).toISOString().substring(0, 10)}`}
      /> */}
      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )} */}
    </StyledRoot>
  );
}
