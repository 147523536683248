// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/scroll-to-top";
import { StyledChart } from "./components/chart";
// ReactToastify
import "react-toastify/dist/ReactToastify.css";
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      {/* // <> */}
      <ScrollToTop />
      <StyledChart />
      <Router />
      {/* </> */}
    </ThemeProvider>
  );
}
