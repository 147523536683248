// component
import { AddBusiness, AddToQueue, ContentCut, DashboardCustomize, Games, ReceiptLong, Storefront } from "@mui/icons-material";
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const backConfig = [
  {
    title: "Back office user",
    path: "/dashboard/backOfficeUser",
    icon: <DashboardCustomize />,
  },
];

export default backConfig;
